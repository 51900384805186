<template>
	<div class="car-list">
		<transition name="fade">
			<div class="preloader" v-if="!loaded">
				<div class="spinner"></div>
			</div>
		</transition>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="3" lg="2" xl="2">
				<filter-side v-if="!$vuetify.breakpoint.smAndDown" />
			</v-col>
			<v-col cols="12" sm="12" md="9" lg="10" xl="10" style="position: relative">
				<car-list ref="carList" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { FETCH_CAR_LIST, SET_REQUEST_READY_STATUS } from '@/store/modules/listing.module';
import { mapActions, mapGetters } from 'vuex';
import CarList from './components/carList.vue';
import filterSide from './filter/filterSide.vue';

export default {
	components: {
		filterSide,
		CarList,
	},
	name: 'Cars',
	data() {
		return {
			reqCount: 0,
			loaded: false,
			cars: {
				carList: {
					data: [],
					count: 0,
				},
			},
		};
	},
	computed: {
		...mapGetters(['filterPayload', 'readyForRequest', 'isFetching']),
	},
	mounted() {
		this.$nextTick(async () => {
			await this.getCarsByFilter(true);
			this.reqCount = 0;
			this.setRequestReadyStatus(true);
		});
	},
	methods: {
		...mapActions({
			getCars: FETCH_CAR_LIST,
			setRequestReadyStatus: SET_REQUEST_READY_STATUS,
		}),
		async getCarsByFilter(init) {
			return new Promise((resolve) => {
				if (this.readyForRequest && this.reqCount === 0) {
					this.reqCount += 1;
					this.getCars({ init, isMobileRequest: this.$vuetify.breakpoint.smAndDown }).finally(() => {
						resolve();

						this.loaded = true;
					});
				}
			});
		},
	},
	watch: {
		filterPayload: {
			handler() {
				this.$nextTick(async () => {
					await this.getCarsByFilter(false);
					this.reqCount = 0;
					this.setRequestReadyStatus(true);
				});
			},
			deep: true,
		},
	},
};
</script>

<style lang="scss" scoped>
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 9999;
	opacity: 1;
	transition: opacity 1s;

	&.fade-out {
		opacity: 0;
	}

	.spinner {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		margin: -30px 0 0 -30px;
		border: 5px solid var(--v-template-color-base);
		border-top-color: var(--v-template-color-darken3);
		border-radius: 100%;
		animation: spin 1s ease-in-out infinite;
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
}
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>
