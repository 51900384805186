<template>
	<v-col>
		<v-row class="grid-display">
			<v-col cols="12" sm="6" md="6" lg="4" xl="3" v-for="(car, index) in mappedCars" :key="`car-${index}`">
				<v-card
					light
					flat
					class="mx-auto rounded-lg car-card"
					:to="{ name: 'carDetails', params: createURLParams(car) }"
					style="position: relative"
				>
					<div class="advantage-car advantage-car--grid pa-2" v-if="car.hasAdvantage || car.isReserved">
						<div v-if="car.hasAdvantaged">
							<v-chip small color="template-color" text-color="template-text">
								<v-icon x-small left small> fas fa-star</v-icon>
								Fırsat aracı
							</v-chip>
						</div>
						<div v-if="car.isReserved">
							<v-chip small color="template-color darken-3" text-color="template-text">
								<v-icon x-small left small>fas fa-check </v-icon>
								Opsiyonlu
							</v-chip>
						</div>
					</div>
					<v-carousel
						hide-delimiters
						hide-delimiter-background
						height="auto"
						show-arrows-on-hover
						:show-arrows="car.photos.length > 1"
					>
						<div class="favorite">
							<v-btn icon width="36" height="36" @click.prevent="addToFavorites(car.id)">
								<v-icon small :color="isFavorite(car.id) ? 'red' : 'grey'">fa-heart</v-icon>
							</v-btn>
						</div>
						<template v-slot:prev="{ on, attrs }">
							<v-btn
								class="direction-icon"
								depressed
								width="30"
								height="30"
								fab
								v-bind="attrs"
								v-on="on"
								@click.prevent
							>
								<v-icon centered small>fas fa-chevron-left</v-icon>
							</v-btn>
						</template>
						<template v-slot:next="{ on, attrs }">
							<v-btn
								class="direction-icon"
								depressed
								width="30"
								height="30"
								fab
								v-bind="attrs"
								v-on="on"
								@click.prevent
							>
								<v-icon centered small>fas fa-chevron-right</v-icon>
							</v-btn>
						</template>
						<v-carousel-item v-for="(photo, pindex) in car.photos" :key="`car-photo-${pindex}`">
							<template v-slot:default>
								<v-img
									:ref="`photo-${index}-${pindex}`"
									class="zoom-img"
									:class="{ 'zoom-img--hover': hasThumbImgZoom }"
									max-height="300"
									contain
									:aspect-ratio="4 / 3"
									:lazy-src="photo"
									:src="photo"
								>
									<div class="fill-height img-gradient"></div>
								</v-img>
							</template>
						</v-carousel-item>
					</v-carousel>
					<v-card-title
						class="font-weight-bold pb-0"
						:class="{ 'd-inline-block text-truncate': $vuetify.breakpoint.smAndUp }"
						:style="{ width: $vuetify.breakpoint.xsOnly ? 'auto' : '25rem' }"
						:title="title(car)"
					>
						{{ title(car) }}
					</v-card-title>
					<v-card-subtitle class="py-0 font-weight-bold car-plate">
						{{ car.plate }}
					</v-card-subtitle>
					<v-card-subtitle>
						<v-row dense no-gutters class="d-flex justify-strech">
							<template v-for="(feature, featureIndex) in car.features">
								<v-col
									class="d-flex flex-column align-center text-center feature-text"
									:class="{ 'feature-text--mobile': $vuetify.breakpoint.smAndDown }"
									:key="`feature-${featureIndex}`"
									v-if="feature.show"
								>
									<v-icon :small="$vuetify.breakpoint.smAndDown" color="template-color darken-3" class="feature-icon">{{
										feature.icon
									}}</v-icon>
									{{ feature.value }}
								</v-col>
							</template>
						</v-row>
					</v-card-subtitle>
					<v-card-text>
						<div
							class="font-weight-bold text-h5 text-md-h4 text-right d-flex"
							:class="showCredit && car.minCreditPerMonth > 0 ? 'justify-space-between' : 'justify-end'"
						>
							<div
								v-if="showCredit && car.minCreditPerMonth > 0"
								class="d-flex align-center text-left font-weight-regular"
								style="max-width: 160px; font-size: 13px; line-height: 13px"
							>
								<span class="font-weight-bold mr-1">
									{{ car.minCreditPerMonth | tl({ fractionCount: 0, avoidEmptyDecimals: '', symbolPosition: 'back' }) }}
								</span>
								| Aylık
							</div>
							<div>
								{{ car.salesPrice | tl({ avoidEmptyDecimals: '' }) }}
							</div>
						</div>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-col>
</template>

<script>
import carFavoriteMixin from '../_mixins/carFavoriteMixin';
import carFeatureMixin from '../_mixins/carFeatureMixin';
import carListMixin from '../_mixins/carListMixin';

export default {
	name: 'GridDisplay',
	mixins: [carFavoriteMixin, carFeatureMixin, carListMixin],
	props: {
		cars: {
			type: Array,
			default: () => [],
			required: true,
		},
		hasThumbImgZoom: {
			type: Boolean,
			default: () => true,
			required: true,
		},
	},
	computed: {
		mappedCars() {
			// eslint-disable-next-line no-unused-vars
			return this.cars.map((car, index) => ({
				...car,
				features: this.mapFeatures(car),
			}));
		},
	},
};
</script>

<style lang="scss" scoped></style>
